code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: "Helvetica Neue", Arial, sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.container {
    width: 70vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

nav {
    width: 100%;
    border-bottom: 1px solid gray;
    display: flex;
    align-items: center;
}

nav .nav-title {
    font-size: 24px;
    margin-right: 1.2rem;
}

nav .nav-item {
    height: 48px;
    line-height: 48px;
}

nav .nav-item:hover {
    background: #CAF0F8;
}

nav .nav-item a {
    display: inline-block;
    padding: 0 8px;
    color: #000;
    height: 100%;
    text-decoration: none;
}

nav .nav-item .active {
    background: #00B4D8;
    color: #fff;
}

nav .nav-title a {
    color: #000;
}

nav .nav-title a:link {
    text-decoration: none;
}

.main-section {
    width: 100%;
}

footer {

}

input {
    border-radius: 4px;
    border: 1px solid gray;
    padding: 0 8px;
}

.content-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.search-result {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tag {
    border-radius: 5px;
    padding: 1px 3px;
}

.tag.blue {
    background: #edf2fb;
    border: 1px solid #b6ccfe;
}

.tag.pink {
    background: #FFEBF2;
    border: 1px solid #ffafcc;
}

.tag.orange {
    background: #FADDC6;
    border: 1px solid #F08D42;
}

button {
    background: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.08) 0 0 0 1px;
    transition: all 100ms;
    padding: 8px;
}

button:hover {
    padding: 9px;
}

em {
    padding: 0 2px;
    background: #ff6392;
    color: #fff;
    font-style: normal;
    border-radius: 3px;
}
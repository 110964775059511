.pkg-card:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.pkg-card {
    padding: 1rem 0;
    width: 100%;
}

.pkg-name {
    font-size: 20px;
    color: #4895ef;
}

.pkg-desc {
    color: black;
    font-size: 18px;
    margin-bottom: 10px;
}

.pkg-props {
    display: flex;
    flex-wrap: wrap;
}

.pkg-prop-item {
    font-size: 14px;
    margin-right: 1rem;
    margin-top: 8px;
}

.pkg-prop-name {
    margin-right: 0.8rem;
}

.pkg-props .pkg-prop-name {
    color: gray;
    margin-right: 0.2rem;
}

.pkg-prop-item .is-url {
    color: #2d57d2;
}
